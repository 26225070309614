var isMobile = mobileAndTabletcheck();
var scrollTop = 0;

var ratio = 9/16;

var bgMusic;
var awardsOpenMusic;

function sound(src) {
	this.sound = document.createElement("audio");
	this.sound.src = src;
	this.sound.setAttribute("preload", "auto");
	this.sound.setAttribute("controls", "none");
	this.sound.style.display = "none";
	document.body.appendChild(this.sound);
	this.play = function(){
		var playPromise = this.sound.play();
		// var playPromise = video.play();
		if (playPromise !== undefined) {
			playPromise.then(_ => {
			  // Automatic playback started!
			  // Show playing UI.
			})
			.catch(error => {
			  // Auto-play was prevented
			  // Show paused UI.
			});
		}
	}
	this.stop = function(){
		this.sound.pause();
	}
}

$(function(){
	if(isMobile) $('body').addClass('isMobile');

	$('.ui.checkbox').checkbox();

	bgMusic = new sound("assets/sound/bg.mp3");
	bgMusic.sound.volume = 0.2;
	bgMusic.sound.loop = true;

	$(".overlay").click(function(e){
		$(this).hide();
	})

	$(".page-btn.go-to-game").click(function(e){
		if(!$("input[name='agree_terms']:checked").length){
			e.preventDefault();
			$(".overlay.agreeterms-fail").show();
		}
	})

	if($("#result-content").length){
		awardsOpenMusic = new sound("assets/sound/awards_open.wav");
		$("#result-content").addClass("startAnim");
		setTimeout(function(){
			$(".result-overlay").addClass("show");
			awardsOpenMusic.play();
		}, 2500)
	}

	var instructionSwiper = new Swiper('.swiper-container.instruction-swiper', {
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
    });
    
	$(window).resize(function(){
		$("#wrapper").height($(window).height()).width($(window).height() * ratio);
		if(isMobile){
			if($(window).width() > $(window).height()){
				$(".resolution-block").show();
			}else{
				$(".resolution-block").hide();
			}
		}
	}).trigger('resize');

	$(window).scroll(function(){
		scrollTop = $(window).scrollTop();
	}).trigger('scroll');
	
})
$(window).load(function() {
	$(".preload").removeClass("preload");
	$(".anim").addClass("ready");
	bgMusic.play();
})